import React from 'react'
import ConfiguredMuiDatable from '../configuredMuiDatable'
import { Container } from '@material-ui/core'
import { HighlightSpecialText } from '../utils'
export default function StudentsPerProgramTable ({ statsData }) {
  let columns = [
    {
      label: 'University',
      name: 'university',
      options: {
        sort: true,
        customBodyRender: renderHighlightableItem
      }
    },
    {
      label: 'Count',
      name: 'count',
      options: {
        sort: true,
        customBodyRender: renderHighlightableItem
      }
    }
  ]
  let data = Object.keys(statsData.studentsPerProgram.table)
    .map(function createRowData (progName) {
      return {
        university: progName,
        count: statsData.studentsPerProgram.table[progName]
      }
    })
    .sort(function getLargerCount (row1, row2) {
      return row2.count - row1.count
    })
  let options = {
    sort: true
  }
  return (
    <Container maxWidth='sm'>
      <ConfiguredMuiDatable
        title={'Students per Program'}
        data={data}
        columns={columns}
        options={options}
      />
    </Container>
  )
}

// ***************************************************
function renderHighlightableItem (value, tableMeta, updateValue) {
  return (
    <HighlightSpecialText
      mainText={`${value}`} // HighlightSpecialText expects this to be a string
      highlightText={tableMeta.tableState.searchText || ''}
    />
  )
}
