import React from 'react'
import StudentsPerProgramTable from '../../components/page/admin/stats/studentsPerProgramTable'
import StatCard from '../../components/page/admin/stats/statCard'
import Layout from '../../components/layout'
import { Container, Box, Grid } from '@material-ui/core'
import { useStats } from '../../client/client'

export default function StatsPage () {
  let statsData = useStats()
  return (
    <Layout>
      <Container maxWidth='md'>
        <Box py={2} />
        <StatRow
          stat1={statsData.matchToll}
          stat2={statsData.crossSchoolMatches}
          whichVal='total'
        />
        <br />
        <StatRow
          stat1={statsData.proposalCompletionSpeed}
          stat2={statsData.proposalCompletionRate}
        />
        <Box py={2} />
        <StudentsPerProgramTable statsData={statsData} />
      </Container>
    </Layout>
  )
}

function StatRow ({ stat1, stat2, whichVal = 'allTimeAverage' }) {
  return (
    <Grid container spacing={2}>
      <Grid item xs={1} />
      <Grid item xs={5}>
        <StatCard
          name={stat1.name}
          value={getValue(stat1)}
          description={stat1.description}
          unit={stat1.unit}
        />
      </Grid>
      <Grid item xs={5}>
        <StatCard
          name={stat2.name}
          value={getValue(stat2)}
          description={stat2.description}
          unit={stat2.unit}
        />
      </Grid>
      <Grid item xs={1} />
    </Grid>
  )

  // ****************************************
  function getValue (stat) {
    // eslint-disable-next-line
    if (whichVal == 'allTimeAverage') {
      return stat.allTimeAverage
      // eslint-disable-next-line
    } else if (whichVal == 'total') {
      return stat.total
    } else {
      return 0
    }
  }
}
