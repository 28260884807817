import React from 'react'
import { Card, CardContent, Typography, Box, Tooltip } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

const styles = makeStyles({
  tooltip: {
    fontSize: '14px'
  }
})
export default function StatCard ({ name, description, value, unit }) {
  const c = styles()
  return (
    <Card raised>
      <CardContent>
        <Box
          display='flex'
          justifyContent='center'
          alignItems='center'
          flexDirection='column'
        >
          <Tooltip title={description} classes={{ tooltip: c.tooltip }}>
            <Typography variant='h6' gutterBottom paragraph>
              {name}
            </Typography>
          </Tooltip>
          <Typography variant='h2'>{value}</Typography>
          <br />
          <Typography
            style={{ color: 'grey' }}
            variant='body1'
          >{`(${unit})`}</Typography>
        </Box>
      </CardContent>
    </Card>
  )
}

// Clean the \n out of the descriptions.
// Make sort work on stats table.
